export class Consts {
  static readonly ROUTES = {
    HOTEL_LIST: '',
    HOTEL_DETAIL: 'hotel/',
    HOTEL_DETAIL_TOKEN: 'hotel/token/'
  };

  static readonly CAMINO_NETWORK_ID_TESTNET = 501;

  static readonly SNACKBAR_CSS_CLASS = 'giata-snack';
  static readonly DIALOG_CSS_CLASS = 'hotelexplorer-dialog';
  static readonly DIALOG_CSS_CLASS_NO_PADDING = 'no-pad';

  static readonly PROVIDER_NAME_GIATA_DRIVE = 'GIATA Drive';
  static readonly PROVIDER_NAME_GEO_EXPLORER = 'GeoExplorer';
  static readonly PROVIDER_NAME_HOLIDAY_CHECK = 'HolidayCheck';
  static readonly PROVIDER_NAME_HELI_VIEW = 'HeliView';


}
