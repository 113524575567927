import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[lineCount]'
})
export class LineCountDirective implements AfterViewInit, OnChanges {

  totalNumOfLines: number | undefined;
  private lineHeight: number | undefined;

  @Input() maxLines = 6;
  @Output() totalNumOfLinesChanged = new EventEmitter<number>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const height = this.el.nativeElement.offsetHeight;
    const lineHeightStr = getComputedStyle(this.el.nativeElement).getPropertyValue("line-height");
    this.lineHeight = +lineHeightStr.split('px')[0];
    this.totalNumOfLines = height / this.lineHeight;
    this.totalNumOfLinesChanged.emit(this.totalNumOfLines);
    this.setElementHeight(this.maxLines)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['maxLines']) {
      if (this.lineHeight) {
        this.setElementHeight(changes['maxLines'].currentValue)
      }
    }
  }

  setElementHeight(numOfLines: number): void {
    if (this.lineHeight) {
      this.renderer.setStyle(this.el.nativeElement, '-webkit-line-clamp', numOfLines);
    }
  }

}
