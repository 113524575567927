import {Component, inject, Input} from '@angular/core';
import {Hotel} from "../../../models/hotel";
import {Store} from "@ngrx/store";
import {selectContextObjectsForFacts} from "../../../store/core.reducer";

@Component({
    selector: 'app-facts-section',
    templateUrl: './facts-section.component.html',
    styleUrls: ['./facts-section.component.scss']
})
export class FactsSectionComponent {
    @Input() hotel: Hotel | null | undefined;
    store = inject(Store);
    contextObjects$ = this.store.select(selectContextObjectsForFacts);

    readonly GIATA_LINK = 'https://myhotel.giatamedia.com/';
}
