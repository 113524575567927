<div class="tile-container">
    <a [routerLink]="HOTEL_DETAIL_ROUTE + hotel?.hotelWalletAddress">
        <div class="card hotel-card rounded-2 overflow-hidden">
            <ng-container *ngIf="hotel?.metadata?.image && !hotel?.metadata?.image?.includes('http:'); else imgPlaceholder">
                <img src="{{hotel?.metadata?.image}}" class="card-img-top hotel-card-image">
            </ng-container>
            <ng-template #imgPlaceholder>
                <div class="placeholder-img-container overflow-hidden">
                    <img src="{{DEFAULT_IMAGE}}" class="card-img-top img-placeholder">
                </div>
            </ng-template>
            <div class="card-body">
                <div class="card-title hotel-card-id"><h4>{{hotel?.id}}</h4></div>
                <div class="card-title tile-title hotel-title m-0"><h4>{{hotel?.metadata?.name}}</h4></div>
                <h5 class="card-text hotel-text pt-2">
                    {{hotel?.metadata?.street}}{{hotel?.metadata?.street ? ', ' : ''}}
                    {{hotel?.metadata?.city}}{{hotel?.metadata?.city ? ', ' : ''}}
                    {{hotel?.metadata?.country}}
                </h5>
            </div>
        </div>
    </a>
</div>
