import {Component, Input, OnInit} from '@angular/core';
import {Hotel} from '../../models/hotel';
import {Consts} from '../../helper/consts';

@Component({
  selector: 'app-hotel-tile',
  templateUrl: './hotel-tile.component.html',
  styleUrls: ['./hotel-tile.component.scss']
})
export class HotelTileComponent implements OnInit {

  @Input() hotel: Hotel | undefined;

  DEFAULT_IMAGE = 'assets/img/placeholder.png';

  HOTEL_DETAIL_ROUTE = Consts.ROUTES.HOTEL_DETAIL;

  constructor() { }

  ngOnInit(): void {
  }

}
