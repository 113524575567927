<div class="description-container rounded">
    <div class="container-head">
        <div class="type-icon-container d-flex align-items-center">
            <img src="assets/icons/base.svg" alt="images"/>
            Description
        </div>
    </div>

    <div class="row p-3 gap-3">
        <div class="col-12 base-text" *ngFor="let section of hotel?.metadata?.texts?.en?.sections">
            <div class="row">
                <div class="section-title">{{section.title}}</div>
                <div class="section-para">{{section.para}}</div>
            </div>
        </div>
    </div>
</div>
