import {Component, Input} from '@angular/core';
import {Hotel} from "../../../models/hotel";

@Component({
    selector: 'app-description',
    templateUrl: './description.component.html',
    styleUrls: ['./description.component.scss']
})
export class DescriptionComponent {
    @Input() hotel: Hotel | null | undefined;
}
