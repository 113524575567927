import {Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {CoreActions} from "./core.actions";
import {catchError, concatMap, delay, filter, from, map, of} from 'rxjs';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {MetamaskService} from '../services/metamask.service';
import {ApiService} from '../services/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Consts} from '../helper/consts';
import {selectSelectedHotel} from './core.reducer';


@Injectable()
export class CoreEffects {
  private static handleWalletError(errorCode: number): string {
    let error = '';
    switch (errorCode) {
      case 4001: {
        error = 'You rejected the connection to your meta mask wallet.';
        break;
      }
      case 9999: {
        error = 'No metamask plugin found.';
        break;
      }
      default: {
        error = 'Unknown error while connecting your metamask wallet';
      }
    }
    return error;
  }

  connectToMetaMask = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.connectToMetaMask),
      concatMap(() => {
        return from(this.metaMaskService.connectToMetaMask()).pipe(
          map(({chainId, accounts}) => {
            return CoreActions.connectToMetaMaskSuccess({walletAddress: accounts[0], chainId});
          }),
          catchError(err => {
            // const errorMessage = CoreEffects.handleWalletError(err.code as number);
            this.snack.open(err, undefined, {
              panelClass: Consts.SNACKBAR_CSS_CLASS,
              duration: 3000
            });
            return of(CoreActions.connectToMetaMaskFail({errorMessage: err}));
          })
        );
      })
    );
  });

  checkIfMetaMaskConnected = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.checkIfMetaMaskConnected),
      concatMap(() => {
        return from(this.metaMaskService.checkIfConnected()).pipe(
          map(({accounts, chainId}) => {
            return CoreActions.connectToMetaMaskSuccess({walletAddress: accounts[0], chainId});
          }),
          catchError(err => {
            const errorMessage = CoreEffects.handleWalletError(err.code as number);
            return of(CoreActions.connectToMetaMaskFail({errorMessage}));
          })
        );
      })
    );
  });

  searchHotels = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.searchHotelsStart),
      concatMap(({request, loadMore}) => {
        return this.apiService.searchHotels$(request).pipe(
          map((response) => {
            return CoreActions.searchHotelsSuccess({response, loadMore})
          }),
          catchError((err) => {
              const errorMessage = 'Error while searching for hotels. Please try again.';
              this.snack.open(errorMessage, undefined, {
                  panelClass: Consts.SNACKBAR_CSS_CLASS,
                  duration: 3000
              });
            return of(CoreActions.searchHotelsFail({}));
          })
        )
      })
    );
  });

  getHotelDetails = createEffect(() => {
      return this.actions$.pipe(
          ofType(CoreActions.getHotelStart),
          concatMap(({hotelWalletAddress}) => {
              return this.apiService.getHotel$(hotelWalletAddress).pipe(
                  map((hotel) => {
                      return CoreActions.getHotelSuccess({hotel});
                  }),
                  catchError(err => {
                      this.router.navigate([Consts.ROUTES.HOTEL_LIST]);
                      const errorMessage = 'An error occurred. There are currently no data available for this hotel.';
                      this.snack.open(errorMessage, undefined, {
                          panelClass: Consts.SNACKBAR_CSS_CLASS,
                          duration: 3000
                      });
                      return of(CoreActions.getHotelFail());
                  })
              );
          })
      )
  });

    getContextTreeForFacts = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.getContextTreeForFactsStart),
            concatMap(({localization}) => {
                return this.apiService.getContextTreeForFacts$(localization).pipe(
                    map((contextTree) => {
                        return CoreActions.getContextTreeForFactsSuccess({contextTree});
                    }),
                    catchError(err => {
                        return of(CoreActions.getContextTreeForFactsFail());
                    })
                );
            })
        )
    });

  getDataTokensForHotel = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.getDataTokensForHotelStart),
      concatMap(({hotel, giataId, txHash}) => {
        return this.apiService.getDataTokensForHotel$(giataId, hotel, txHash).pipe(
          map(({hotel, dataTokens}) => CoreActions.getDataTokensForHotelSuccess({hotel, dataTokens, txHash})),
          catchError(() => {
            // TODO: add error snack
            return of(CoreActions.getDataTokensForHotelFail({}));
          })
        )
      })
    );
  });

  createDataTokenMetadata = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.createMetadataStart),
      concatLatestFrom(() => this.store.select(selectSelectedHotel)),
      concatMap(([{metadata, image}, hotel]) => {
        return this.apiService.createDataTokenMetadata$(metadata, image).pipe(
          map(({url}) => CoreActions.createMetadataSuccess({url, metadata, hotel: hotel!})),
          catchError((err) => {
            const errorMessage = 'Error while trying to create token metadata. Please try again.';
            this.snack.open(errorMessage, undefined, {
              panelClass: Consts.SNACKBAR_CSS_CLASS,
              duration: 3000
            });
            return of(CoreActions.createMetadataFail({}));
          })
        )
      })
    );
  });

  onNewMetadataCreation = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.createMetadataSuccess),
      concatMap(({url, metadata, hotel}) => {
        return from(this.metaMaskService.mintToken(url)).pipe(
          map((receipt) => {
            const successMessage = 'Token successfully minted!';
            this.snack.open(successMessage, undefined, {
              panelClass: Consts.SNACKBAR_CSS_CLASS,
              duration: 3000
            });
            return CoreActions.mintNewTokenSuccess({txHash: receipt.hash, giataId: metadata.giataId, hotel});
          }),
          catchError((err) => {
            this.snack.open(err, undefined, {
              panelClass: Consts.SNACKBAR_CSS_CLASS,
              duration: 3000
            });
            return of(CoreActions.mintNewTokenFailed());
          })
        )
      })
    );
  });

  onMintSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.mintNewTokenSuccess),
      map(({txHash, giataId, hotel}) => CoreActions.getDataTokensForHotelStart({txHash, giataId, hotel}))
    );
  });

  onTxHashDataTokenSearchSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.getDataTokensForHotelSuccess),
      filter(({dataTokens, txHash}) => Boolean(txHash) && (dataTokens?.length ?? 0) === 0),
      delay(10000),
      map(({txHash, hotel}) => CoreActions.getDataTokensForHotelStart({txHash, giataId: hotel.metadata?.giataId!, hotel}))
    );
  });

  onDataTokenSearchWithTxHashSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.getDataTokensForHotelSuccess),
      filter(({txHash, dataTokens}) => Boolean(txHash) && (dataTokens?.length ?? 0) > 0),
      map(({txHash, dataTokens}) => CoreActions.getDataTokensForHotelWithTxhashSuccess({txHash: txHash!, dataToken: dataTokens[0]}))
    );
  });


  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private snack: MatSnackBar,
    private apiService: ApiService,
    private metaMaskService: MetamaskService,
  ) {
  }
}
