import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HotelListViewComponent} from './components/hotel-list-view/hotel-list-view.component';
import {HotelDetailViewComponent} from './components/hotel-detail-view/hotel-detail-view.component';
import {Consts} from './helper/consts';
import {HotelDetailResolver} from './resolver/hotel-detail.resolver';

const routes: Routes = [
  {
    path: Consts.ROUTES.HOTEL_LIST, component: HotelListViewComponent
  },
  {
    path: Consts.ROUTES.HOTEL_DETAIL + ':hotelWalletAddress', component: HotelDetailViewComponent, resolve: {data: HotelDetailResolver}
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
