import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import { take} from 'rxjs';
import {HotelListPageRequest} from "../../interfaces/hotel-list-page-request";
import {PaginationActionEnum} from "../../enum/pagination-action.enum";
import {Store} from '@ngrx/store';
import {CoreActions} from '../../store/core.actions';
import {selectHotelListViewModel} from '../../store/core.reducer';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-hotel-list-view',
  templateUrl: './hotel-list-view.component.html',
  styleUrls: ['./hotel-list-view.component.scss']
})
export class HotelListViewComponent implements OnInit {

  readonly PAGINATION_ACTIONS = PaginationActionEnum;

  viewModel$ = this.store.select(selectHotelListViewModel);

  filterForm: FormGroup;

  constructor(private apiService: ApiService, private store: Store, fb: FormBuilder) {
    this.filterForm = fb.group({
      hotelName: [],
      city: [],
      country: [null, Validators.maxLength(2)],
    })
  }

  ngOnInit(): void {
    this.viewModel$.pipe(take(1)).subscribe(vm => {
      if (!vm?.lastPageResponse) {
        this.store.dispatch(CoreActions.searchHotelsStart({request: {pageSize: 50}}));
      }

      if (vm.lastPageRequest) {
        this.filterForm.patchValue({
          hotelName: vm?.lastPageRequest?.name,
          city: vm?.lastPageRequest?.city,
          country: vm?.lastPageRequest?.country,
        });
      }
    })
  }

  filterHotelData() {
    this.viewModel$.pipe(take(1)).subscribe(({lastPageRequest}) => {
      let newRequest: HotelListPageRequest = {
        pageSize: 50
      };
      if (lastPageRequest) {
        newRequest = {
          ...lastPageRequest
        };
      }
      const name = this.filterForm.controls['hotelName'].value?.trim()?.length ? this.filterForm.controls['city']?.value : undefined;
      const city = this.filterForm.controls['city']?.value?.trim()?.length ? this.filterForm.controls['city']?.value : undefined;
      const country = this.filterForm.controls['country']?.value?.trim()?.length  ? this.filterForm.controls['country']?.value : undefined;

      newRequest = {
        ...newRequest,
        name,
        country,
        city,
      };

      this.store.dispatch(CoreActions.searchHotelsStart({request: newRequest}));
    });
  }

  resetFilter() {
    this.filterForm.reset();
    this.store.dispatch(CoreActions.searchHotelsStart({request: {pageSize: 50}}));
  }

  navToPage(direction: PaginationActionEnum): void {
    this.viewModel$.pipe(take(1)).subscribe(({lastPageRequest, lastPageResponse}) => {
      let newRequest: HotelListPageRequest = {
        pageSize: 50
      };
      if (lastPageRequest) {
        newRequest = {
          ...lastPageRequest
        };
      }
      switch (direction) {
        case PaginationActionEnum.first: {
          if ((lastPageResponse?.pageNumber ?? 0) === 0) {
            return;
          }
          newRequest.pageNumber = 0;
          break;
        }
        case PaginationActionEnum.last: {
          if ((lastPageResponse?.pageNumber ?? 0) === ((lastPageResponse?.totalPages ?? 0) - 1)) {
            return;
          }
          newRequest.pageNumber = lastPageResponse!.totalPages! - 1;
          break;
        }
        case PaginationActionEnum.next: {
          if (((lastPageResponse?.totalPages ?? 0) - 1) <= (lastPageResponse?.pageNumber ?? 0)) {
            return;
          }
          newRequest.pageNumber = lastPageResponse!.pageNumber! + 1;
          break;
        }
        case PaginationActionEnum.prev: {
          if ((lastPageResponse?.pageNumber ?? 0) === 0) {
            return;
          }
          newRequest.pageNumber = lastPageResponse!.pageNumber! - 1;
          break;
        }
      }
      this.store.dispatch(CoreActions.searchHotelsStart({request: newRequest}));
    })
  }
}
