<div class="" *ngLet="viewModel$ | async as vm">
    <form [formGroup]="filterForm">
        <div class=" background p-2 mb-4 ">
            <div class=" row px-lg-5 align-items-center gap-y-3">

                <div class="col-6 col-lg-3">
                    <input class="input-style" type="text" placeholder="Hotel name" formControlName="hotelName">
                </div>
                <div class="col-6 col-lg-3">
                    <input class="input-style" type="text" placeholder="City" formControlName="city">
                </div>
                <div class="col-12 col-lg-3 py-3">
                    <input class="input-style" type="text" placeholder="Country 2 letter code"
                           formControlName="country">
                </div>
                <div class="col-12  col-lg-3">
                    <button class="primary w-100 m-0"
                            (click)="filterHotelData()"><h4 class="text-white">Apply Filter</h4>
                    </button>
                </div>
            </div>
        </div>
    </form>

    <div class="container">
        <div class="row">
            <div class="col-6 hotel-list-count"><h2>{{ (vm?.lastPageResponse?.totalElements ?? 0) }} Hotels</h2></div>
            <ng-container *ngIf="vm.filterCount == 0 ; else filterCounter">
                <div class="col-6 hotel-filter-active"><h5>No filters active</h5></div>
            </ng-container>
            <ng-template #filterCounter>
                <div class="col-6 hotel-filter-active"><h5 class="">{{vm.filterCount}} filter active</h5></div>
            </ng-template>
        </div>
        <div>
            <ng-container *ngIf="!(vm.hotelSearchLoading); else loader">
                <div class="row hotel-row" *ngIf="vm?.lastPageResponse; else noContent">
                    <ng-container *ngFor="let hotel of (vm.lastPageResponse?.content)">
                        <div class="col-12 col-lg-3 col-md-6">
                            <app-hotel-tile [hotel]="hotel"></app-hotel-tile>
                        </div>
                    </ng-container>
                </div>
                <div class="row justify-content-center pagination-row" *ngIf="vm?.lastPageResponse">
                    <div class="col-auto nav-col">
                        <div [ngClass]="{deactivated: (vm?.lastPageResponse?.pageNumber ?? 0) === 0}"
                             class="nav-container"
                             (click)="navToPage(PAGINATION_ACTIONS.first)">
                            <mat-icon [svgIcon]="'doubleLeft'"></mat-icon>
                        </div>
                    </div>
                    <div class="col-auto nav-col">
                        <div [ngClass]="{deactivated: (vm?.lastPageResponse?.pageNumber ?? 0) === 0}"
                             class="nav-container"
                             (click)="navToPage(PAGINATION_ACTIONS.prev)">
                            <mat-icon [svgIcon]="'left'"></mat-icon>
                        </div>
                    </div>
                    <div class="col-auto nav-col">
                        <div class="nav-container">
                            {{ ((vm?.lastPageResponse?.pageNumber ?? 0) + 1) }}
                            / {{ ((vm?.lastPageResponse?.totalPages ?? 0)) }}
                        </div>
                    </div>
                    <div class="col-auto nav-col">
                        <div
                                [ngClass]="{deactivated: (vm?.lastPageResponse?.pageNumber ?? 0) === ((vm?.lastPageResponse?.totalPages ?? 0) - 1)}"
                                class="nav-container" (click)="navToPage(PAGINATION_ACTIONS.next)">
                            <mat-icon [svgIcon]="'right'"></mat-icon>
                        </div>
                    </div>
                    <div class="col-auto nav-col">
                        <div
                                [ngClass]="{deactivated: (vm?.lastPageResponse?.pageNumber ?? 0) === ((vm?.lastPageResponse?.totalPages ?? 0) - 1)}"
                                class="nav-container" (click)="navToPage(PAGINATION_ACTIONS.last)">
                            <mat-icon [svgIcon]="'doubleRight'"></mat-icon>
                        </div>
                    </div>
                </div>
                <!--    <div class="row justify-content-center">-->
                <!--      <ng-container *ngIf="(hotelsAdditionalLoading$ | async); else loadMore">-->
                <!--        <div class="col-auto">-->
                <!--          <div class="spinner-grow" role="status">-->
                <!--            <span class="visually-hidden">Loading...</span>-->
                <!--          </div>-->
                <!--          <div class="loading">-->
                <!--            is loading more ...-->
                <!--          </div>-->
                <!--        </div>-->
                <!--      </ng-container>-->
                <!--&lt;!&ndash;   count variable of all maximal available hotels needed  &ndash;&gt;-->
                <!--      <ng-template #loadMore>-->
                <!--        <div class="col-auto">-->
                <!--          <button type="button" (click)="getHotelData(true)">-->
                <!--            load more-->
                <!--          </button>-->
                <!--        </div>-->
                <!--      </ng-template>-->
                <!--    </div>-->
            </ng-container>
        </div>
    </div>
    <ng-template #noContent>
    </ng-template>
    <ng-template #loader>
        <div class="row loader-row justify-content-center">
            <div class="col-auto">
                <div class="loading">
                    <div class="spinner-grow" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

