export enum PaginationActionEnum {
  "first" = "first",
  "prev" = "prev",
  "next" = "next",
  "last" = "last",
}

export enum TokenTypeEnum {
  "base" = "base",
  "multimedia" = "multimedia",
  "images" = "images",
  "ratings" = "ratings",
  "roomType" = "roomType",
}
