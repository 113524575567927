import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '../services/api.service';

@Injectable({providedIn: 'root'})
export class HotelDetailResolver implements Resolve<boolean>{
  constructor(private apiService: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<boolean> | Promise<boolean> | boolean {
    const hotelWalletAddress = route.paramMap.get('hotelWalletAddress');
    // if (hotelId) {
    //   this.apiService.getDataTokensForHotel$(hotelId);
    // }
    return true;
  }

}
