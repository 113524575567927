import {Component, Input, OnInit} from '@angular/core';
import {Image} from "../../../interfaces/image";
import {Hotel} from "../../../models/hotel";

@Component({
    selector: 'app-photo-section',
    templateUrl: './photo-section.component.html',
    styleUrls: ['./photo-section.component.scss']
})
export class PhotoSectionComponent implements OnInit {
    @Input() hotel: Hotel | null | undefined;

    readonly PLACEHOLDER = '../../assets/img/placeholder.png'

    highlightImage: { href: string, id: number } | undefined;

    ngOnInit(): void {
        if (this.hotel?.metadata?.images?.length) {
            const heroImage = this.hotel?.metadata?.images.find(img => img.heroImage) ?? this.hotel?.metadata?.images[0];

            this.highlightImage = {
                href: heroImage.sizes['1080'].href,
                id: heroImage?.id
            };
        } else {
            if (this.hotel?.metadata?.image) {
                this.highlightImage = {
                    href: this.hotel?.metadata?.image,
                    id: 4711
                };
            } else {
                this.highlightImage = {
                    href: this.PLACEHOLDER,
                    id: 4711
                };
            }
        }
    }


    setAsHighlightImage(image: Image) {
        const newHighlightImage = {
            href: image.sizes["1080"].href,
            id: image.id
        };
        this.highlightImage = newHighlightImage;
    }
}
