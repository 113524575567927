import {Component, OnInit} from '@angular/core';
import {ApiService} from './services/api.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {Store} from '@ngrx/store';
import {CoreActions} from './store/core.actions';
import {DescriptionOfFacts} from "./interfaces/description-of-facts";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Hotel Explorer powered by ITsquare';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(CoreActions.checkIfMetaMaskConnected());
    this.store.dispatch(CoreActions.getContextTreeForFactsStart({localization: 'en'}));

    this.matIconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/logo.svg'));
    this.matIconRegistry.addSvgIcon('children', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/children.svg'));
    this.matIconRegistry.addSvgIcon('persons', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/persons.svg'));
    this.matIconRegistry.addSvgIcon('pets', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/pets.svg'));
    this.matIconRegistry.addSvgIcon('request', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/request.svg'));
    this.matIconRegistry.addSvgIcon('close', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/close.svg'));
    this.matIconRegistry.addSvgIcon('emailBlue', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/email_blue.svg'));
    this.matIconRegistry.addSvgIcon('thumbUp', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/thumb-up.svg'));
    this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/check.svg'));
    this.matIconRegistry.addSvgIcon('homeMarker', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/home-marker.svg'));
    this.matIconRegistry.addSvgIcon('phone', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/phone.svg'));
    this.matIconRegistry.addSvgIcon('mail', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/mail.svg'));
    this.matIconRegistry.addSvgIcon('openNew', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/open-new.svg'));
    this.matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/location.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/calendar.svg'));
    this.matIconRegistry.addSvgIcon('calendarRefresh', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/calendar_refresh.svg'));
    this.matIconRegistry.addSvgIcon('bed', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/bed.svg'));
    this.matIconRegistry.addSvgIcon('doubleLeft', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/double-left.svg'));
    this.matIconRegistry.addSvgIcon('doubleRight', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/double-right.svg'));
    this.matIconRegistry.addSvgIcon('left', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/left.svg'));
    this.matIconRegistry.addSvgIcon('right', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/right.svg'));
    this.matIconRegistry.addSvgIcon('filter', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/filter.svg'));
    this.matIconRegistry.addSvgIcon('timer', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/timer.svg'));
    this.matIconRegistry.addSvgIcon('menuDown', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/menu-down.svg'));
    this.matIconRegistry.addSvgIcon('door', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/door.svg'));
    this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/home.svg'));
    this.matIconRegistry.addSvgIcon('bedWhite', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/bed_white.svg'));
    this.matIconRegistry.addSvgIcon('doorWhite', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/door_white.svg'));
    this.matIconRegistry.addSvgIcon('homeGrey', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/home_grey.svg'));
    this.matIconRegistry.addSvgIcon('map', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/map.svg'));
    this.matIconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/info.svg'));
    this.matIconRegistry.addSvgIcon('homeMapMarker', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/home_map_marker.svg'));
    this.matIconRegistry.addSvgIcon('list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/list.svg'));
    this.matIconRegistry.addSvgIcon('globe', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/svg/globe.svg'));
  }
}
