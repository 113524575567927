<div class="pb-1 grad">
    <div class="bg-white">
        <div class="container py-3 d-flex justify-content-between">
            <a [routerLink]="''">
                <img class="h-[54px]" src="assets/icons/giata-logo.svg">
            </a>
            <div class="d-flex gap-2 justify-content-end align-items-center">
                <img class="h-[32px]" src="assets/icons/bed-icon.svg" alt="">
                <p class="m-0">Hotel Explorer</p>
            </div>
        </div>
    </div>
</div>
