import {Injectable} from '@angular/core';
import {ethers} from 'ethers';
import {Store} from '@ngrx/store';
import {FormatTypes} from 'ethers/lib/utils';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Consts} from '../helper/consts';


const hotelDataToken = require('../abi/HotelDataToken.json');

@Injectable({providedIn: 'root'})
export class MetamaskService {

  private readonly CONTRACT_WALLET = '0x9bBAd24e84Ee481ffBd55Eed3De1111b9E46F4d7';



  constructor(private store: Store, private snack: MatSnackBar) {
  }

  async connectToMetaMask(): Promise<{ accounts: string[], chainId: number }> {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const res: string[] = await provider.send("eth_requestAccounts", []);
        const { chainId } = await provider.getNetwork();
        return Promise.resolve({accounts: res, chainId});
      } catch (e: any) {
        const error = this.handleWalletError(e.code);
        return Promise.reject(error);
      }
    } else {
      const error = this.handleWalletError('NO_PLUGIN_FOUND');
      return Promise.reject(error);
    }
  }

  async checkIfConnected(): Promise<{ accounts: string[], chainId: number }> {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const res = await provider.listAccounts();
        const { chainId } = await provider.getNetwork();
        return Promise.resolve({accounts: res, chainId});
      } catch (e: any) {
        const error = this.handleWalletError(e.code);
        return Promise.reject(error);
      }
    } else {
      const error = this.handleWalletError('NO_PLUGIN_FOUND');
      return Promise.reject(error);
    }
  }

  async getTransactionFeeEstimate(): Promise<number> {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const feeEstimate: ethers.providers.FeeData = await provider.getFeeData();
        const mintFee = ((feeEstimate.gasPrice?.toNumber() ?? 0) / 1e18);
        return Promise.resolve(mintFee);
      } catch (e) {
        return Promise.reject('Could not get gas fee estimate');
      }
    } else {
      return Promise.reject('Wallet not connected.');
    }
  }

  async getNetworkId(): Promise<number> {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      try {
        const { chainId } = await provider.getNetwork();
        return Promise.resolve(chainId);
      } catch (e) {
        return Promise.reject('Could not get chain ID');
      }
    } else {
      return Promise.reject('Wallet not connected.');
    }
  }

  async mintToken(tokenUri: string): Promise<any> {
    if (window.ethereum) {
      const iface = new ethers.utils.Interface(hotelDataToken);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(this.CONTRACT_WALLET, iface.format(FormatTypes['full']), signer);
      try {
        // @ts-ignore
        const receipt = await contract.mint(tokenUri);
        console.log(receipt);
        return Promise.resolve(receipt);
      } catch (e: any) {
        console.error(e);
        const error = this.handleWalletError(e.code);
        this.snack.open(error, undefined, {
          panelClass: Consts.SNACKBAR_CSS_CLASS,
          duration: 3000
        });
        return Promise.reject(error);
      }
    } else {
      return Promise.reject('Wallet not connected.');
    }
  }

  handleWalletError(errorCode: string | number): string {
    let error = '';
    switch (errorCode) {
      case 4001: {
        error = 'You rejected the connection to your meta mask wallet.';
        break;
      }
      case 'ACTION_REJECTED': {
        error = 'You rejected the transaction.';
        break;
      }
      case 'NO_PLUGIN_FOUND': {
        error = 'No metamask plugin found.';
        break;
      }
      default: {
        error = 'Unknown error while connecting your metamask wallet';
      }
    }
    return error;
  }
}
