<div class="image-data-token-container">
    <div class="container-head">
        <div class="type-icon-container d-flex align-items-center">
            <img src="assets/icons/images.svg" alt="images"/>
            <h1 class="m-0">Photos</h1>
        </div>
    </div>
    <div class="highlight-image-container">
        <img [src]="highlightImage?.href" alt=""/>
    </div>
    <div class="images-container">
        <ng-container *ngIf="hotel?.metadata?.images?.length; else single">
            <ng-container *ngFor="let image of hotel?.metadata?.images">
                <div [ngClass]="{selected: image.id === highlightImage?.id}" class="token-image"
                     (click)="setAsHighlightImage(image)">
                    <ng-container *ngIf="image.sizes['320'].href && !image.sizes['320'].href?.includes('http:')">
                        <img [src]="image.sizes['320'].href">
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #single>
            <div [ngClass]="{selected: true}" class="token-image">
                <ng-container *ngIf="hotel?.metadata?.image && !hotel?.metadata?.image?.includes('http:'); else noImage">
                    <img [src]="hotel?.metadata?.image">
                </ng-container>
                <ng-template #noImage>
                    <img [src]="PLACEHOLDER">
                </ng-template>
            </div>
        </ng-template>
    </div>
</div>
