<ng-container *ngLet="(hotelDetailViewModel$ | async) as vm">
    <div class="background-header py-3">
        <div class="container">
            <div class="d-flex heading-row align-items-center">

                <button class="secondary" id="back-btn" [routerLink]="'/'">
                    <svg id="back-icon">
                        <use [attr.href]="'assets/icons/arrow-left.svg#arrow-left'"></use>
                    </svg>
                    <h4 class="text-color-primary" id="back-text">Back</h4>
                </button>
                <ng-container *ngIf="vm?.hotel">
                    <div class="heading d-flex align-items-center w-100 overflow-hidden">
                        <h4 class="text-nowrap">Hotel-Address: </h4>
                        <h4 class="text-color ps-1 text-clip">{{vm?.hotel?.hotelWalletAddress}}</h4>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>

    <div class="container hotel-detail-container">
        <ng-container *ngIf="vm?.loading === false; else loaderRow">
            <ng-container *ngIf="vm?.hotel; else noContent">
                <div class="row content-row">
                    <div class="col-12 ">

                        <div class="row details-row">
                            <div class="col-12">
                                <div class="px-lg-4 py-3">
                                    <div class="row">
                                        <div class="col-12 details-head-column">
                                            <h2 class="">Hotel Details</h2>
                                        </div>
                                        <div class="col-12 details-content-column">
                                            <div class="row row-container">
                                                <div class="col-12 col-md-6 col-lg-4">
                                                    <div class="outline small d-flex">
                                                        <ng-container *ngIf="vm?.hotel?.metadata?.image && !vm?.hotel?.metadata?.image?.includes('http:'); else noImage">
                                                            <img class="w-100" [src]="vm?.hotel?.metadata?.image">
                                                        </ng-container>
                                                        <ng-template #noImage>
                                                            <img class="w-100" [src]="PLACEHOLDER">
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-8">
                                                    <div class="row details-row">
                                                        <div class="col-12">
                                                            <div class="outline small">
                                                                <div class="detail-sub align-items-center">
                                                                    <h4 class="head">Name: </h4>
                                                                    <span class="detail-sub content"><p>{{ vm?.hotel?.metadata?.name }}</p></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="outline small">
                                                                <div class="detail-sub align-items-center">
                                                                    <h4 class="head">Street: </h4>
                                                                    <span class="detail-sub content"><p>{{ vm?.hotel?.metadata?.street }}</p></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="outline small">
                                                                <div class="detail-sub align-items-center">
                                                                    <h4 class="head">City: </h4>
                                                                    <span class="detail-sub content"><p>{{ vm?.hotel?.metadata?.city }}</p></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="outline small">
                                                                <div class="detail-sub">
                                                                    <h4 class="head">County: </h4>
                                                                    <span class="detail-sub content"><p>{{ vm?.hotel?.metadata?.country }}</p></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="outline small">
                                                        <div class="detail-sub line-clamp d-flex col align-items-center; ">
                                                            <h4 class="head">Transaction:</h4><h4
                                                                class="text-color ps-2 w-50 text-clip">{{ vm?.hotel?.txHash }}</h4>
                                                            <h5 class="extra ">(Block:&nbsp; <span
                                                                    class="extra-content">{{ vm?.hotel?.blockNumber }}</span>)
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-container *ngIf="vm?.hotel?.metadata?.description">
                                                    <div class="col-12">
                                                        <div class="outline small">
                                                            <div class="detail-sub flex-column align-items-start">
                                                                <div class="head desc"><h4>Description:</h4></div>
                                                                <span class="detail-sub content line-clamp"><p>{{ vm?.hotel?.metadata?.description }}</p></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <ng-container *ngIf="vm?.hotel?.metadata?.images || (vm?.hotel?.metadata?.image && !vm?.hotel?.metadata?.image?.includes('http:'))">
                                <div class="col-12 col-lg-6">
                                    <app-photo-section [hotel]="vm?.hotel"/>
                                </div>
                            </ng-container>

                            <div class="col-12 col-lg-6">
                                <ng-container *ngIf="vm?.hotel?.metadata?.texts?.en?.sections?.length">
                                    <app-description [hotel]="vm?.hotel"/>
                                </ng-container>
                                <ng-container *ngIf="vm?.hotel?.metadata?.facts">
                                    <app-facts-section [hotel]="vm?.hotel"/>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #noContent>
                <div class="no-data">
                    No Transactions
                </div>
            </ng-template>

        </ng-container>
        <ng-template #loaderRow>
            <div class="row loader-row justify-content-center">
                <div class="col-auto">
                    <div class="loading">
                        <div class="spinner-grow" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ng-container>
