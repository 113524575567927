import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {HotelListPageRequest} from '../interfaces/hotel-list-page-request';
import {Hotel} from '../models/hotel';
import {SearchResponse} from '../interfaces/search-response';
import {DataToken} from '../interfaces/data-token';
import {DataTokenMetadata} from '../interfaces/data-token-metadata';
import {DescriptionOfFacts} from "../interfaces/description-of-facts";


export const CoreActions = createActionGroup({
    source: 'Core',
    events: {
        'Connect to meta mask': emptyProps(),
        'Connect to meta mask success': props<{ walletAddress: string, chainId: number | null }>(),
        'Connect to meta mask fail': props<{ errorMessage: string; }>(),

        'Check if meta mask connected': emptyProps(),

        'Search Hotels Start': props<{ request: HotelListPageRequest, loadMore?: boolean }>(),
        'Search Hotels Success': props<{ response: SearchResponse<Hotel>, loadMore?: boolean }>(),
        'Search Hotels Fail': props<{ errorMessage?: string; }>(),

        'Get Hotel Start': props<{ hotelWalletAddress: string }>(),
        'Get Hotel Success': props<{ hotel: Hotel}>(),
        'Get Hotel Fail': emptyProps(),



        
        'Get Data Tokens For Hotel Start': props<{ giataId: number, hotel?: Hotel, txHash?: string }>(),
        'Get Data Tokens For Hotel Success': props<{ hotel: Hotel, dataTokens: Array<DataToken>, txHash?: string }>(),
        'Get Data Tokens For Hotel Fail': props<{ errorMessage?: string; }>(),

        'Get Data Tokens For Hotel With TxHash Success': props<{ dataToken: DataToken, txHash: string }>(),

        'Select Data Token': props<{ dataToken: DataToken; }>(),
        'Reset Data Token': emptyProps(),

        'Create Metadata Start': props<{ metadata: DataTokenMetadata, image?: File }>(),
        'Create Metadata Success': props<{ url: string, metadata: DataTokenMetadata, hotel: Hotel }>(),
        'Create Metadata Fail': props<{ errorMessage?: string; }>(),

        'Mint New Token Success': props<{ txHash: string, giataId: number, hotel: Hotel }>(),
        'Mint New Token Failed': emptyProps(),

        'Get Context Tree For Facts Start': props<{ localization: string }>(),
        'Get Context Tree For Facts Success': props<{ contextTree: DescriptionOfFacts }>(),
        'Get Context Tree For Facts Fail': emptyProps(),
    },
});
