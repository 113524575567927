import {createFeature, createReducer, createSelector} from '@ngrx/store';
import {CoreActions} from "./core.actions";
import {immerOn} from 'ngrx-immer/store';
import {enableMapSet} from "immer"
import {AppState} from '../interfaces/app-state';
import {Hotel} from '../models/hotel';
import {HotelListPageRequest} from '../interfaces/hotel-list-page-request';
import {SearchResponse} from '../interfaces/search-response';
import {DataToken} from '../interfaces/data-token';
import {Consts} from '../helper/consts';
import {DataTokenState} from '../enum/data-token-state';
import {DataTokenType} from '../enum/data-token-type';
import {ContextObject} from "../models/context-object";
import {FactObject} from "../models/fact-object";
import {SubObject} from "../models/sub-object";
import {FactDefinition} from "../interfaces/fact-definition";
import {AttributeObject} from "../models/attribute-object";

enableMapSet();

export const initialCoreStateState: AppState = {
    walletConnected: false,
    currentWalletAddress: null,
    walletLoading: false,
    walletError: null,
    isMobile: false,
    chainId: null,
    lastHotelPageRequest: null,
    hotelSearchAdditionalLoading: false,
    hotelSearchLoading: false,
    lastHotelSearchResponse: null,
    hotelList: null,
    selectedHotel: null,
    hotelDetailLoading: false,
    selectedHotelGiataId: null,
    selectedHotelLoading: false,
    selectedHotelDataTokens: null,
    selectedDataToken: null,
    tokenCreateLoading: false,
    pendingTokens: new Map<number, Array<DataToken>>(),
    contextTree: null,
};

export const coreFeature = createFeature({
    name: 'core',
    reducer: createReducer(
        initialCoreStateState,
        immerOn(CoreActions.connectToMetaMask, (state, {}) => {
            state.walletLoading = true;
            state.walletError = null;
        }),
        immerOn(CoreActions.connectToMetaMaskSuccess, (state, {walletAddress, chainId}) => {
            state.walletLoading = false;
            state.walletError = null;
            state.currentWalletAddress = walletAddress;
            state.chainId = chainId;
            state.walletConnected = Boolean(walletAddress);
        }),
        immerOn(CoreActions.connectToMetaMaskFail, (state, {errorMessage}) => {
            state.walletLoading = false;
            state.walletError = errorMessage;
        }),


        immerOn(CoreActions.searchHotelsStart, (state, {request, loadMore}) => {
            state.hotelSearchAdditionalLoading = loadMore ?? false;
            state.hotelSearchLoading = !loadMore;
            state.lastHotelPageRequest = request;
        }),
        immerOn(CoreActions.searchHotelsSuccess, (state, {response, loadMore}) => {
            state.hotelSearchAdditionalLoading = false;
            state.hotelSearchLoading = false;
            if (!loadMore) {
                state.hotelList = response.content
            } else {
                state?.hotelList?.push(...response.content);
            }
            state.lastHotelSearchResponse = response;
        }),
        immerOn(CoreActions.searchHotelsFail, (state, {errorMessage}) => {
            state.hotelSearchAdditionalLoading = false;
            state.hotelSearchLoading = false;
        }),


        immerOn(CoreActions.getHotelStart, (state, {hotelWalletAddress}) => {
            state.selectedHotelLoading = true;
        }),
        immerOn(CoreActions.getHotelSuccess, (state, {hotel}) => {
            state.selectedHotel = hotel;
            state.selectedHotelLoading = false;
        }),
        immerOn(CoreActions.getHotelFail, (state) => {
            state.selectedHotelLoading = false;
        }),


        immerOn(CoreActions.getDataTokensForHotelStart, (state, {hotel, giataId, txHash}) => {
            state.selectedHotelGiataId = giataId;
            if (!Boolean(txHash)) {
                // TODO: check
                state.selectedHotelDataTokens = null;
            }

            state.selectedHotelLoading = true;
        }),
        immerOn(CoreActions.getDataTokensForHotelSuccess, (state, {hotel, dataTokens}) => {
            if (!state.hotelList) {
                state.hotelList = [hotel];
            }
            if ((state?.selectedHotelDataTokens?.length ?? 0) > 0) {
                dataTokens.forEach(token => {
                    if (!(state?.selectedHotelDataTokens?.some(oldToken => oldToken.tokenId === token.tokenId))) {
                        state.selectedHotelDataTokens!.push(token);
                    }
                })
            } else {
                state.selectedHotelDataTokens = dataTokens;
            }
            state.selectedHotelLoading = false;
        }),
        immerOn(CoreActions.selectDataToken, (state, {dataToken}) => {
            state.selectedDataToken = dataToken;
        }),
        immerOn(CoreActions.resetDataToken, (state) => {
            state.selectedDataToken = null;
        }),
        immerOn(CoreActions.createMetadataStart, (state) => {
            state.tokenCreateLoading = true;
        }),
        immerOn(CoreActions.createMetadataFail, (state) => {
            state.tokenCreateLoading = false;
        }),
        immerOn(CoreActions.mintNewTokenFailed, (state) => {
            state.tokenCreateLoading = false;
        }),
        immerOn(CoreActions.mintNewTokenSuccess, (state, {hotel, giataId, txHash}) => {
            state.tokenCreateLoading = false;
            const newDataToken: DataToken = {
                hotelAddress: hotel.hotelWalletAddress!,
                txHash,
                content: null,
                providerName: 'HotelExplorer User',
                state: DataTokenState.pending,
                blockNumber: '',
                type: DataTokenType.images,
                giataId
            };
            const pendingTokens = state.pendingTokens.get(giataId);
            if (pendingTokens && (pendingTokens?.length ?? 0) > 0) {
                pendingTokens.push(newDataToken);
                state.pendingTokens.set(giataId, pendingTokens);
            } else {
                state.pendingTokens.set(giataId, [newDataToken]);
            }
        }),
        immerOn(CoreActions.getDataTokensForHotelWithTxhashSuccess, (state, {txHash, dataToken}) => {
            const pendingTokens = state.pendingTokens.get(dataToken.giataId);
            if (pendingTokens && (pendingTokens?.length ?? 0) > 0) {
                const index = pendingTokens.findIndex(token => token.txHash === dataToken.txHash);
                if (index > -1) {
                    pendingTokens.splice(index, 1);
                    if (pendingTokens.length === 0) {
                        state.pendingTokens.delete(dataToken.giataId);
                    } else {
                        state.pendingTokens.set(dataToken.giataId, pendingTokens);
                    }
                }
            }
        }),
        immerOn(CoreActions.getContextTreeForFactsStart, (state, {localization}) => {
        }),
        immerOn(CoreActions.getContextTreeForFactsSuccess, (state, {contextTree}) => {
            console.log(contextTree);
            state.contextTree = contextTree;
        }),
        immerOn(CoreActions.getContextTreeForFactsFail, (state) => {
            console.error('kein ContextTree');
        }),
    ),
});

export const {
    name, // feature name
    reducer, // feature reducer
    selectCoreState,
    selectCurrentWalletAddress,
    selectIsMobile,
    selectWalletLoading,
    selectWalletError,
    selectWalletConnected,
    selectChainId,
    selectLastHotelPageRequest,
    selectHotelList,
    selectHotelSearchAdditionalLoading,
    selectHotelSearchLoading,
    selectLastHotelSearchResponse,
    selectSelectedHotelDataTokens,
    selectSelectedHotelGiataId,
    selectSelectedHotelLoading,
    selectSelectedDataToken,
    selectTokenCreateLoading,
    selectPendingTokens,
    selectSelectedHotel,
    selectContextTree,
} = coreFeature;


export const selectHotelListViewModel = createSelector(
    selectHotelList,
    selectHotelSearchLoading,
    selectHotelSearchAdditionalLoading,
    selectLastHotelPageRequest,
    selectLastHotelSearchResponse,
    (hotelList,
     hotelSearchLoading,
     additionalLoading,
     lastPageRequest,
     lastPageResponse) => {
        let filterCount = 0;
        if (lastPageRequest) {
            if (lastPageRequest?.name) {
                ++filterCount;
            }
            if (lastPageRequest?.city) {
                ++filterCount;
            }
            if (lastPageRequest?.country) {
                ++filterCount;
            }
            if (lastPageRequest?.giataId) {
                ++filterCount;
            }
        }

        const vm: {
            hotelList: Array<Hotel> | null,
            hotelSearchLoading: boolean,
            additionalLoading: boolean,
            lastPageRequest: HotelListPageRequest | null,
            lastPageResponse: SearchResponse<Hotel> | null,
            filterCount: number
        } = {
            hotelList,
            hotelSearchLoading,
            additionalLoading,
            lastPageRequest,
            lastPageResponse,
            filterCount
        }
        return vm;
    }
);

export const selectPendingTokenForHotel = createSelector(
    selectPendingTokens,
    selectSelectedHotel,
    (pendingTokenMap, hotel) => {
        if (!hotel?.metadata?.giataId) {
            return [];
        }
        return pendingTokenMap.get(hotel!.metadata!.giataId!) ?? [];
    }
);

export const selectHotelDetailViewModel = createSelector(
    selectSelectedHotel,
    selectSelectedHotelDataTokens,
    selectSelectedHotelLoading,
    selectPendingTokenForHotel,
    (hotel, dataTokens, loading, pendingTokens) => {

        const vm: {
            hotel: Hotel | null,
            dataTokens: Array<DataToken> | null,
            loading: boolean,
        } = {
            hotel,
            dataTokens: [...(dataTokens ?? []), ...pendingTokens],
            loading
        };

        return vm;
    }
);

export const selectCanMint = createSelector(
    selectWalletConnected,
    selectChainId,
    (walletConnected, chainId) => {
        return walletConnected && chainId === Consts.CAMINO_NETWORK_ID_TESTNET;
    }
);

export const selectAddNewTokenViewModel = createSelector(
    selectSelectedHotel,
    selectCanMint,
    selectTokenCreateLoading,
    (hotel, canMint, loading) => {
        const vm: {
            hotel: Hotel | null,
            canMint: boolean,
            loading: boolean
        } = {
            hotel,
            loading,
            canMint
        };
        return vm;
    }
);

export const selectFactsArray = createSelector(
    selectSelectedHotel,
    selectContextTree,
    (selectedHotel, contextTree) => {
        if (!selectedHotel || !selectedHotel?.metadata?.facts) {
            return null;
        }
        let factsArray: FactObject[] = [];
        selectedHotel?.metadata?.facts?.forEach((value, key) => {
            let factObject: FactObject;
            let attributeArray: AttributeObject[] = [];

            value[0].attributes?.forEach((attributeValue, attributeKey) => {
                let attributeObject: AttributeObject = {
                    id: Number(attributeKey),
                    label: contextTree?.attributes?.get(attributeKey)?.label,
                    unit: attributeValue.unitDefId ? contextTree?.units?.get(attributeValue.unitDefId.toString())?.label : undefined,
                    value: attributeValue.value ?? undefined
                }
                attributeArray.push(attributeObject);
            });

            factObject = {
                label: contextTree?.facts?.get(key)?.label,
                id: Number(key),
                attributes: attributeArray.length ? attributeArray : undefined,
            };
            factsArray.push(factObject);
        });

        return factsArray;
    }
);

export const selectContextObjectsForFacts = createSelector(
    selectSelectedHotel,
    selectContextTree,
    selectFactsArray,
    (selectedHotel, contextTree, currentHotelFacts) => {
        if (!selectedHotel || !selectedHotel?.metadata?.facts || !currentHotelFacts) {
            return null;
        }

        let contextObjects: ContextObject[] = [];
        contextTree?.contextTree?.forEach((contextTreeValue, contextTreeKey) => {

            //NOTE: contextObjects are needed to combine multiple facts to one top heading, this objects can contain multiple sub-headings, that can include several facts as well!
            //If sub-facts are declared in contextTree than put this facts array to corresponding sub object, if no sub-facts are declared in contextTree than put filtered facts directly to corresponding contextObject

            let contextSubsArray: SubObject[] = [];
            let contextObject: ContextObject | undefined;


            if (contextTreeValue.facts.length && currentHotelFacts.length) {
                const contextFactsArray = currentHotelFacts.filter(hotelfacts => (hotelfacts.id ? contextTreeValue.facts.includes(hotelfacts.id.toString()) : false));
                if (contextFactsArray.length > 0) {
                    contextObject = {
                        label: contextTreeValue.label,
                        facts: contextFactsArray,
                        subs: undefined
                    }
                }
            }
            if (contextTreeValue.sub && currentHotelFacts.length) {
                contextTreeValue.sub.forEach((contextTreeSubValue, contextTreeSubKey) => {
                    const contextFactsArray = currentHotelFacts.filter(hotelfacts => (hotelfacts.id ? contextTreeSubValue.facts.includes(hotelfacts.id!.toString()) : false));
                    if (contextFactsArray.length > 0) {
                        let subObject: SubObject = {
                            label: contextTreeSubValue.label,
                            facts: contextFactsArray
                        }
                        contextSubsArray.push(subObject);
                    }
                });

                if (contextSubsArray.length) {
                    contextObject = {
                        ...contextObject,
                        label: contextTreeValue.label,
                        subs: contextSubsArray
                    }
                }

            } else {
                contextObject = undefined;
            }

            if (contextObject) {
                contextObjects.push(contextObject);
            }
        });

        return contextObjects;
    }
);
