import {Component, OnDestroy, OnInit} from '@angular/core';
import {Consts} from '../../helper/consts';
import {selectHotelDetailViewModel} from '../../store/core.reducer';
import {Store} from '@ngrx/store';
import {Subject, takeUntil} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {concatLatestFrom} from '@ngrx/effects';
import {CoreActions} from '../../store/core.actions';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-hotel-detail-view',
    templateUrl: './hotel-detail-view.component.html',
    styleUrls: ['./hotel-detail-view.component.scss']
})
export class HotelDetailViewComponent implements OnInit, OnDestroy {
    hotelDetailViewModel$ = this.store.select(selectHotelDetailViewModel);
    cleanupSubject = new Subject<void>();

    readonly PLACEHOLDER = '../assets/img/placeholder.png'
    readonly ARROW_ICON = '../assets/icons/arrow-left.svg'

    constructor(private store: Store, private route: ActivatedRoute, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.route.paramMap.pipe(
            concatLatestFrom(() => this.hotelDetailViewModel$),
            takeUntil(this.cleanupSubject)
        ).subscribe(([paramMap, vm]) => {
            const hotelWalletAddress = paramMap.get('hotelWalletAddress');
            if (hotelWalletAddress) {
                this.store.dispatch(CoreActions.getHotelStart({hotelWalletAddress}));
            }
        })
    }

    ngOnDestroy(): void {
        this.cleanupSubject.next();
        this.cleanupSubject.complete();
    }
}
