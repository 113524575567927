<ng-container *ngLet="(contextObjects$ | async) as contextObjects">
    <div class="facts-container rounded">
        <div class="container-head">
            <div class="type-icon-container d-flex align-items-center">
                <img src="assets/icons/facts.svg"/>
                Facts
            </div>
        </div>

        <div class="row p-3 context-objects-container">
            <div class="col-12 base-text" *ngFor="let contextObject of (contextObjects)">
                <div class="context-sub-container">
                    <div class="section-title ">{{contextObject.label}}</div>
                    <ng-container *ngIf="contextObject.subs?.length">
                        <ng-container *ngFor="let sub of contextObject.subs">
                            <div class="sub-title">{{sub.label}}</div>

                            <ng-container *ngFor="let subFact of sub?.facts">
                                <div class="d-flex facts-element-container w-100">
                                    <div class="check-icon"><img [src]="'assets/icons/check.svg'"></div>
                                    <div class="d-flex flex-column gap-2 justify-content-center">
                                        <ul class="fact-title m-0 p-0">{{subFact?.label}}</ul>
                                        <ng-container *ngFor="let attribute of subFact.attributes">
                                            <div class="list-margin ">
                                                <div class="d-flex align-items-start flex-wrap gap-2">
                                                    <div class="custom-circle my-auto"></div>
                                                    <p class="attribute-element">{{attribute.label}}{{attribute.value ? ': ' : ''}}</p>
                                                    <ng-container *ngIf="attribute.value">
                                                        <p class="attribute-element">{{attribute.value}}</p>
                                                    </ng-container>
                                                    <ng-container *ngIf="attribute.unit">
                                                        <p class="attribute-element">{{attribute.unit}}</p>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="contextObject.facts?.length">
                        <ng-container *ngFor="let fact of contextObject.facts">
                            <div class="d-flex facts-element-container check-icon w-100">
                                <img [src]="'assets/icons/check.svg'">
                                <div class="d-flex flex-column gap-2 justify-content-center">
                                    <ul class="fact-title m-0 p-0">{{fact.label}}</ul>
                                    <ng-container *ngFor="let attribute of fact.attributes">
                                        <div class="list-margin">
                                            <div class="d-flex align-items-start flex-wrap gap-2">
                                                <div class="custom-circle my-auto"></div>
                                                <p class="attribute-element">{{attribute.label}}{{attribute.value ? ': ' : ''}}</p>
                                                <ng-container *ngIf="attribute.value">
                                                    <p class="attribute-element">{{attribute.value}}</p>
                                                </ng-container>
                                                <ng-container *ngIf="attribute.unit">
                                                    <p class="attribute-element">{{attribute.unit}}</p>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
