import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HotelListViewComponent } from './components/hotel-list-view/hotel-list-view.component';
import { HotelDetailViewComponent } from './components/hotel-detail-view/hotel-detail-view.component';
import { HotelTileComponent } from './components/hotel-tile/hotel-tile.component';
import {MatCardModule} from '@angular/material/card'
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgLetModule} from 'ng-let';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LineCountDirective} from './directives/line-count.directive';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as coreReducer from './store/core.reducer';
import {CoreEffects} from './store/core.effects';
import {environment} from '../environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import { LoaderComponent } from './components/loader/loader.component';
import {ConcatPipe} from './pipe/concat.pipe';
import { PhotoSectionComponent } from './components/hotel-detail-view/photo-section/photo-section.component';
import { DescriptionComponent } from './components/hotel-detail-view/description/description.component';
import { FactsSectionComponent } from './components/hotel-detail-view/facts-section/facts-section.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HotelListViewComponent,
    HotelDetailViewComponent,
    HotelTileComponent,
    LineCountDirective,
    LoaderComponent,
    ConcatPipe,
    PhotoSectionComponent,
    DescriptionComponent,
    FactsSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgLetModule,
    FormsModule,
    StoreModule.forRoot({[coreReducer.name]: coreReducer.reducer}, {}),
    EffectsModule.forRoot([CoreEffects]),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    MatSnackBarModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
