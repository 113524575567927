import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, map, Observable, of} from 'rxjs';
import {Hotel} from '../models/hotel';
import {HotelDetailState} from '../models/hotel-detail.state';
import {HotelsListState} from "../models/hotel-list.state";
import {SearchResponse} from "../interfaces/search-response";
import {environment} from "../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {plainToInstance} from "class-transformer";
import {HotelListPageRequest} from "../interfaces/hotel-list-page-request";
import {Consts} from '../helper/consts';
import {DataToken} from '../interfaces/data-token';
import {DataTokenType} from '../enum/data-token-type';
import {DataTokenMetadata} from '../interfaces/data-token-metadata';
import {MetadataCreateResponse} from '../interfaces/metadata-create-response';
import {HotelMetaData} from "../interfaces/hotel-meta-data";
import {DescriptionOfFacts} from "../interfaces/description-of-facts";
import {FactDefinition} from "../interfaces/fact-definition";
import {FactsSection} from "../interfaces/facts-section";
import {Fact} from "../interfaces/fact";
import {Attribute} from "../interfaces/attribute";
import {Unit} from "../interfaces/unit";
import {MotifType} from "../interfaces/motif-type";
import {AttributeDefinition} from "../interfaces/attribute-definition";
import {ContextTreeInternationalization} from "../interfaces/context-tree-Internationalization";

@Injectable({providedIn: 'root'})
export class ApiService {
    constructor(private httpClient: HttpClient) {
    }

    private MIN_LOADING_TIME = 500;
    private PAGE_SIZE = 1000;

    // readonly STATIC_HOTEL_DETAIL_DATA = Consts.HOTEL_DETAIL_DATA;
    // readonly STATIC_SEARCH_RESPONSE = Consts.SEARCH_RESPONSE_DATA;

    private hotelDetailState: HotelDetailState = new HotelDetailState();
    private hotelsListState: HotelsListState = new HotelsListState();

    static getHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    private onFinalize(reqStart: number, subject: BehaviorSubject<any>, value: any, loadMore: boolean = false): void {
        const reqEnd = performance.now();
        const delta = reqEnd - reqStart;
        if (delta < this.MIN_LOADING_TIME) {
            setTimeout(() => {
                value.loading = false;
                subject.next(value);
            }, this.MIN_LOADING_TIME - delta)
        } else {
            value.loading = false;
            subject.next(value);
        }
    }


    searchHotels$(hotelListPageRequest: HotelListPageRequest): Observable<SearchResponse<Hotel>> {
        const headers = ApiService.getHeaders();
        return this.httpClient.post<SearchResponse<Hotel>>(environment.api.base + environment.api.hotel.base + environment.api.hotel.search, JSON.stringify(hotelListPageRequest), {headers})
    }

    getHotel$(hotelWalletId: string): Observable<Hotel> {
        const headers = ApiService.getHeaders();
        const url = environment.api.base + environment.api.hotel.base + '/' + hotelWalletId;
        const hotelFromServer$ = this.httpClient.get<Hotel>(url, {headers});
        return (hotelFromServer$)
            .pipe(
                map((hotelFromServer) => {
                    let hotelMetadata: HotelMetaData;
                    let hotel: Hotel;

                    if (!hotelFromServer.metadata?.facts) {
                        return hotelFromServer;
                    }
                    let factsMap = new Map<string, Array<FactDefinition>>(Object.entries(hotelFromServer.metadata.facts));


                    factsMap.forEach((values, key, map) => {
                        values.forEach(value => {
                            value.attributes = new Map<string, AttributeDefinition>(Object.entries(value.attributes ?? {}));
                        })
                    });
                    hotelMetadata = {
                        ...hotelFromServer.metadata,
                        facts: factsMap
                    }
                    hotel = {
                        ...hotelFromServer,
                        metadata: hotelMetadata,
                    }
                    return hotel;
                })
            );
    }

    getContextTreeForFacts$(localization: string): Observable<DescriptionOfFacts> {
        const headers = ApiService.getHeaders();
        const url = 'https://myhotel.giatamedia.com/i18n/facts/' + localization;

        // const contextTreeInternationalization$ = this.httpClient.get<ContextTreeInternationalization>(url, {headers});
        // return contextTreeInternationalization$.pipe(
        //     map((contextTreeInternationalization => {
        //             const descriptionOfFacts = contextTreeInternationalization.internationalization.en;
        //             //@ts-ignore
        //             const contextTreeMap = new Map<string, FactsSection>(Object.entries(descriptionOfFacts.contextTree));
        //
        //             contextTreeMap.forEach((value, key, map) => {
        //                 value.sub = new Map<string, FactsSection>(Object.entries(value?.sub ?? {}));
        //             })
        //
        //             const contextTree: DescriptionOfFacts = {
        //                 contextTree: descriptionOfFacts.contextTree ? contextTreeMap : undefined,
        //                 facts: descriptionOfFacts.facts ? new Map<string, Fact>(Object.entries(descriptionOfFacts.facts)) : undefined,
        //                 attributes: descriptionOfFacts.attributes ? new Map<string, Attribute>(Object.entries(descriptionOfFacts.attributes)) : undefined,
        //                 units: descriptionOfFacts.units ? new Map<string, Unit>(Object.entries(descriptionOfFacts.units)) : undefined,
        //                 motifTypes: descriptionOfFacts.motifTypes ? new Map<string, MotifType>(Object.entries(descriptionOfFacts.motifTypes)) : undefined
        //             }
        //             return contextTree;
        //         })
        //     )
        // );

        const contextTreeFromServer$ = this.httpClient.get<DescriptionOfFacts>(url, {headers});
        return contextTreeFromServer$.pipe(
            map((contextTreeFromServer => {
                    //@ts-ignore
                    const contextTreeMap = new Map<string, FactsSection>(Object.entries(contextTreeFromServer.en.contextTree));

                    contextTreeMap.forEach((value, key, map) => {
                        value.sub = new Map<string, FactsSection>(Object.entries(value?.sub ?? {}));
                    })

                    const contextTree: DescriptionOfFacts = {
                        //@ts-ignore
                        contextTree: contextTreeFromServer.en.contextTree ? contextTreeMap : undefined,
                        //@ts-ignore
                        facts: contextTreeFromServer.en.facts ? new Map<string, Fact>(Object.entries(contextTreeFromServer.en.facts)) : undefined,
                        //@ts-ignore
                        attributes: contextTreeFromServer.en.attributes ? new Map<string, Attribute>(Object.entries(contextTreeFromServer.en.attributes)) : undefined,
                        //@ts-ignore
                        units: contextTreeFromServer.en.units ? new Map<string, Unit>(Object.entries(contextTreeFromServer.en.units)) : undefined,
                        //@ts-ignore
                        motifTypes: contextTreeFromServer.en.motifTypes ? new Map<string, MotifType>(Object.entries(contextTreeFromServer.en.motifTypes)) : undefined
                    }
                    return contextTree;
                })
            )
        );
    }


    getDataTokensForHotel$(giataId: number, hotel?: Hotel, txHash?: string): Observable<{
        hotel: Hotel,
        dataTokens: Array<DataToken>
    }> {
        let dataTokenUrl = `${environment.api.base}${environment.api.hotel.base}/${giataId}${environment.api.hotel.dataToken}`;
        const hotelDetailsUrl = `${environment.api.base + environment.api.hotel.base}/${giataId + environment.api.hotel.singleHotel}`;
        if (txHash) {
            dataTokenUrl += `?txHash=${txHash}`;
        }

        const headers = ApiService.getHeaders();
        const dataTokens$ = this.httpClient.get<DataToken[]>(dataTokenUrl, {
            headers,
        });
        const hotelDetail$ = hotel ? of(hotel) : this.httpClient.get<Hotel>(hotelDetailsUrl, {headers});
        return forkJoin([hotelDetail$, dataTokens$])
            .pipe(
                map((res) => {
                    const [hotelDetail, dataTokens] = res;
                    const modifiedDataTokens: Array<DataToken> = [];
                    dataTokens.forEach(token => {
                        // @ts-ignore
                        const contentMap = new Map<DataTokenType, DataToken>(Object.entries(token?.content));
                        contentMap.forEach((value, key, map) => {
                            const newToken: DataToken = {
                                ...token,
                                content: value
                            }
                            switch (key) {
                                case DataTokenType.base: {
                                    newToken.type = DataTokenType.base;
                                    break;
                                }
                                case DataTokenType.facts: {
                                    newToken.type = DataTokenType.facts;
                                    break;
                                }
                                case DataTokenType.geo: {
                                    newToken.type = DataTokenType.geo;
                                    break;
                                }
                                case DataTokenType.images: {
                                    newToken.type = DataTokenType.images;
                                    break;
                                }
                                case DataTokenType.multimedia: {
                                    newToken.type = DataTokenType.multimedia;
                                    break;
                                }
                                case DataTokenType.ratings: {
                                    newToken.type = DataTokenType.ratings;
                                    break;
                                }
                                case DataTokenType.roomTypes: {
                                    newToken.type = DataTokenType.roomTypes;
                                    break;
                                }
                            }
                            modifiedDataTokens.push(newToken);
                        });
                    });
                    console.log(modifiedDataTokens);
                    return {hotel: hotelDetail, dataTokens: modifiedDataTokens};
                })
            );
    }

    createDataTokenMetadata$(metadata: DataTokenMetadata, image?: File): Observable<MetadataCreateResponse> {
        const URL = `${environment.api.base + environment.api.hotel.base}`;
        const formData = new FormData();
        if (image) {
            formData.append('image', image, image.name);
        }
        formData.append('metadata', new Blob([JSON.stringify(metadata)], {
            type: "application/json"
        }));
        return this.httpClient.post<MetadataCreateResponse>(URL, formData);
    }

}
